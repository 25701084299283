/***************** Events Collection *****************/

#events {
  .event-list {
  list-style:none;
  width:100%;
  margin:0;
  h2, h3 {margin-top:5px; margin-bottom:5px; text-align:left !important;}
  p {text-align:left !important;}
  }
  .date {
    a, a:hover, a:visited {color:#333 !important; text-decoration:none;}
    display: block;
    width: 80px;
    height: 105px;
    margin: 0px 30px 50px 0px;
    text-align: center;
    font-family: "Helvetica",sans-serif;
    position: relative;
    float: left;
    .binds {
      position: absolute;
      height: 12px;
      width: 60px;
      background: transparent;
      border: 2px solid #a32733;
      border-width: 0 5px;
      top: -4px;
      left: 0;
      right: 0;
      margin: auto;
      }
    .month {
      background: #d01031;
      display: block;
      text-transform: uppercase;
      padding: 6px 6px 3px;
      color: #fff;
      font-size: 12px;
      font-weight: bold;
      border-bottom: 2px solid #a32733;
      box-shadow: inset 0 -1px 0 0 #666;
      }
    .day {
      display: block;
      margin: 0;
      padding: 0;
      font-size: 40px;
      box-shadow: 0 0 3px #ccc;
      position: relative;
      background: #FFF none repeat scroll 0% 0%;
      }
    .day::after {
      content: '';
      display: block;
      height: 100%;
      width: 96%;
      position: absolute;
      top: 3px;
      left: 2%;
      z-index: -1;
      box-shadow: 0 0 3px #ccc;
    }
    .day::before {
      content: '';
      display: block;
      height: 100%;
      width: 90%;
      position: absolute;
      top: 6px;
      left: 5%;
      z-index: -1;
      box-shadow: 0 0 3px #ccc;
      }
    }

  .date.past {
    .binds {
      position: absolute;
      height: 12px;
      width: 60px;
      background: transparent;
      border: 2px solid #999;
      border-width: 0 5px;
      top: -4px;
      left: 0;
      right: 0;
      margin: auto;
      }
    .month {
      background: #999;
      color: #eee;
      border-bottom: 2px solid #777;
      box-shadow: inset 0 -1px 0 0 #777;
      }
    .day {
      box-shadow: 0 0 3px #ccc;
      color:#ccc;
      }
    .day::after {
      box-shadow: 0 0 3px #ccc;
      }
    .day::before {
      box-shadow: 0 0 3px #ccc;
    }
  }
}

a.post-link.past, a:visited.post-link.past {color:#666 !important;}

.event h1.headline {font-size: 42px;}


@media (max-width: 600px) {
  #events {
  .date {
    float: none !important;
    margin: 0 auto !important;
    margin-top:30px !important;
  }
  .event-list {
    h2, h3, p {text-align:center !important;}
  }
  }
}
.card{
  li{
    list-style-type: none;
    text-align: center;
  }
  background-color: transparent;
  border: none;
  margin-bottom: 5px;

}
